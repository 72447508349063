import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs';
import {forkJoin} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class SettingsApiService {

    url = environment.apiUrl;

    constructor(
        private http: HttpClient) {
    }

    getRatingSettings() {
        return this.http.get(this.url + 'api/settings/rating');
    }

    getSettings() {
        return this.http
            .get(this.url + 'api/settings');
    }

    editSetting(key, data) {
        return this.http
            .patch(this.url + 'api/settings/' + key, {value : data});
    }

    getCustomTerms() {
        return this.http
            .get(this.url + 'api/terms');
    }

    editCustomTerms(data, slug) {
        return this.http
            .patch(this.url + 'api/terms/' + slug, {term: data});
    }
}
