import {Injectable} from '@angular/core';
import {GlobalApiService} from '../global.api.service';

@Injectable()
export class RouteProvider {
    route;
    locationRoute;
urlRoute;
    constructor() {
    }


    getRoute() {
        return this.route;
    }
    getUrlRoute() {
        return this.urlRoute;
    }
    getLocationRoute() {
        return this.locationRoute;
    }

    setRoute(route) {
        this.route = route;
    }

    setUrlRoute(route) {
        this.urlRoute = route;
    }
    setLocationRoute(route: any) {
        if (route) {
            const location = route.split('#/');
            this.locationRoute = location[1];
        } else {
            this.locationRoute = null;
        }
    }
}

