import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-gif-preview',
    templateUrl: './gif-preview.component.html',
    styleUrls: ['./gif-preview.component.scss'],
})
export class GifPreviewComponent implements OnInit {
    @Input() title: string;
    @Input() path: string;
    @Input() id;
    @Input() parentPath;
    @Input() extraClass;
    @Input() mainParent;
    @Input() action;
    url: unknown = '';


    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.getGif();
    }

    getGif() {
        this.http.get(this.path, {responseType: 'blob'})
            .subscribe((val) => {
                    this.blobToBase64(val).then(res => {
                        this.url = res;
                    });
                },
                response => {
                    // console.log('GET - getGif - in error', response);
                },
                () => {
                    // console.log('GET - getGif - observable is now completed.');
                });
    }


    navigateVideo() {
        if (this.parentPath.includes('dashboard')) {
            this.router.navigateByUrl('dashboard/videos/');

        } else {
            const parent = this.mainParent !== '' ? '/' + this.mainParent : '';
            this.router.navigateByUrl(this.parentPath + '/videos/' + this.id + parent);

        }
    }

    blobToBase64 = blob => {
        const reader = new FileReader();
        if (blob instanceof Blob) {
            reader.readAsDataURL(blob);
        }
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };


}
