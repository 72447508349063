import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class GlobalApiService {

    url = environment.apiUrl;
    expandedTreeNodes: any = [];
    expandedFieldGuide: any;
    expandedNodes = new Set();

    constructor(
        private http: HttpClient) {
    }


    getCategories() {
        return this.http
            .get(this.url + 'api/categories');

    }

    getCustomTerms() {
        return this.http.get(environment.apiUrl + 'api/terms');
    }
}
