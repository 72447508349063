<div class="preview">
	<div (click)="action? action():navigateVideo()">
		<div class="image-gif {{extraClass}}" *ngIf="url!==''">
			<ion-img [src]="url"></ion-img>
			<ion-icon name="play-circle"></ion-icon>

		</div>
		<div *ngIf="url==''">
			<div class="image-placeholder {{extraClass}}">
				<ion-icon name="play-circle"></ion-icon>
			</div>
		</div>
	</div>

</div>
