import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.info('production mode enabled');
  enableProdMode();
}
else {
  console.info('production mode not enabled');
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && environment.production) {
    navigator.serviceWorker.register('/ngsw-worker.js')
      .then((registration) => console.log('service worker registered', registration))
      .catch(err => console.error('service worker registration error', err));
  }
}).catch(err => console.log(err));
