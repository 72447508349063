import {Component, OnInit} from '@angular/core';
import {ContentApiService} from '../../../_services/content.api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryProvider} from '../../../_services/utils/category.provider';

@Component({
    selector: 'app-quizzes-list',
    templateUrl: './quizzes-list.component.html',
    styleUrls: ['./quizzes-list.component.scss'],
})
export class QuizzesListComponent implements OnInit {
    quizzes;
    category = '';
    parentPath = '';
    parentName = '';

    constructor(private route: ActivatedRoute, private contentApi: ContentApiService, private globalCategories: CategoryProvider) {
        this.route.params.subscribe(params => {
            const categoryObj = globalCategories.getCategoryBySlug(params.category);
            this.category = categoryObj.slug;
            this.parentPath = categoryObj.route;
            this.parentName = categoryObj.name;
        });
    }

    ngOnInit() {
        this.contentApi.getQuizzesBasicByCategory(this.category).subscribe((data) => {
            this.quizzes = data;
        });
    }

}
