import * as CryptoJS from 'crypto-js';
import {Injectable} from '@angular/core';

@Injectable()
export class Crypt {
    key: CryptoKey;
    iv: any;

    constructor() {
        this.key = CryptoJS.enc.Base64.parse('#base64Key#');
        this.iv = CryptoJS.enc.Base64.parse('#base64IV#');

    }

    encrypt(data) {
        return CryptoJS.AES.encrypt(data, this.key, {iv: this.iv}).toString();
    }

    decrypt(data) {
        return CryptoJS.AES.decrypt(data, this.key, {iv: this.iv}).toString(CryptoJS.enc.Utf8);
    }
}
