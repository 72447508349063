import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs';
import {forkJoin} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class WasteImpactApiService {

    url = environment.apiUrl;


    constructor(
        private http: HttpClient) {
    }

    getImpactAndWasteTypes(){
        return forkJoin([
            this.http.get(this.url + 'api/impactTypes'),
            this.http.get(this.url + 'api/wasteTypes')]);
    }

    getImpactTypes() {
        return this.http
            .get(this.url + 'api/impactTypes');
    }


    getImpactTypeById(id) {
        return this.http
            .get(this.url + 'api/impactTypes/' + id);
    }

    addImpactType(name) {
        return this.http
            .post(this.url + 'api/impactTypes', {name: name});
    }

    editImpactType(id, name) {
        return this.http.patch(this.url + 'api/impactTypes/' + id + '/name', {name: name});
    }

    getWasteTypes() {
        return this.http
            .get(this.url + 'api/wasteTypes');
    }


    getWasteTypeById(id) {
        return this.http
            .get(this.url + 'api/wasteTypes/' + id);
    }

    addWasteType(name) {
        return this.http
            .post(this.url + 'api/wasteTypes', {name: name});
    }

    editWasteType(id, name) {
        return this.http.patch(this.url + 'api/wasteTypes/' + id + '/name', {name: name});
    }

}
