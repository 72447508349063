import {Injectable} from '@angular/core';
import {CustomTermsProvider} from './utils/customTerms.provider';

@Injectable()
export class RoadmapHandlersService {
    constructor(private customTerms: CustomTermsProvider) {
    }

    ciTerm = this.customTerms.getCIterm();
    ciTermPlural = this.customTerms.getCIpluralTerm();
    continuousImprovementSingle = this.customTerms.getContinuousImprovementTerm();
    cslipPluralTerm = this.customTerms.getCSlipPluralTerm();
    roadmapData = {
        'ci_leadership': [
            {
                'title': 'Leadership Behaviours',
                'not_applied': 'Little or no evidence of the leadership actions being applied.',
                'partially_applied': 'Some evidence of leadership actions being applied by a minority of leaders.',
                'fully_applied': 'Evidence of leadership actions being applied by the majority of leaders and coaches.',
                'sustained_improved': 'The leadership actions are fully embedded and all leaders and coaches are clearly committed to applying them in all situations.'
            },
            {
                'not_applied': this.ciTerm + ' is not recognised by the team.',
                'partially_applied': 'A small number of the team are involved with ' + this.ciTerm + '.',
                'fully_applied': 'The majority of the team are involved in ' + this.ciTerm + ' and are proactively making improvements.',
                'sustained_improved': 'Everyone is involved in improvement - there is a real ' + this.ciTerm + ' culture.',
                'title': 'Engagement in ' + this.ciTerm + ' (Front Line teams)'
            },
            {
                'not_applied': 'Required knowledge & experience for specific roles is not defined. Training plans do not exist.',
                'partially_applied': 'Training plans have a weak relationship to business & individuals requirements.\nThere is little or no evidence of training effectiveness being reviewed with individuals.',
                'fully_applied': 'Action plans in place to close gaps between actual & required levels of capability.\nEvidence of training effectiveness being reviewed with individuals.',
                'sustained_improved': 'There is a high level of capability across the team.\nThe team is proactively looking for further learning opportunites.',
                'title': 'Training/ Capability\n(for role & personal development)'
            },
            {
                'not_applied': 'Managers/leaders are doing the improvement.\nThere is no coaching of the front line teams.',
                'partially_applied': 'Leadership support front line teams.\nThere is some support provided through sponsorship or sporadic coaching.',
                'fully_applied': 'Leadership engaged in supporting improvements.\nLeaders are committing more than 20% of their working hours to coaching.',
                'sustained_improved': '\n\n\nLeaders are coaching with 50%+* of time spent coaching in the field.\n\n\n* includes indoor and outdoor if applicable',
                'title': 'Leaders as Coaches'
            }
        ],
        'flow': [
            {
                'not_applied': 'No VSM for the process.\nNo Boston Matrix.\nNo engagement of teams in improving the process or value stream.',
                'partially_applied': 'VSM is complete for some streams but the data is not complete and the information flow is not captured.\nBoston matrix is used to prioritise improvements.\nLittle VSM engagement with the teams.',
                'fully_applied': 'Process flow, material flow and information flow is completed and maintained.\nVSM linked to pipeline VSM.\nThe VSM is used to engage the teams and to drive 90 day plans to achieve the future state.',
                'sustained_improved': 'The VSMs are regularly reviewed, updated and used to engage the teams.\nAnnual re-map of Interim State linked to strategy deployment.\nVSM fits with organisation wide Future State.',
                'title': 'Value Stream Mapping'
            },
            {
                'not_applied': 'There is no visualisation of status vs. plan\nNo effective control of work In progress (WIP). Management time spent moving mail.',
                'partially_applied': 'There is some visualisation of status vs. plan but this is not in real time and there are no visual signals to highlight concerns.\nOverall WIP monitored but not effectively managed.',
                'fully_applied': 'Key processes of the value stream show status vs. plan and visual signals are used to highlight concerns.\nWIP is controlled in the value stream in terms of sequence and quantity.',
                'sustained_improved': 'The value stream plan vs. actual status is clear.\nVisual control signals are deployed to show the status in real time.\nValue stream control methods are integrated and aligned across other value streams or dependable processes.',
                'title': 'Visual Control'
            },
            {
                'not_applied': 'Facility layout is not designed to enable flow, reduce distance travelled and minimise waste. Routings are complex with back flows.',
                'partially_applied': 'Layout has some alignment to process sequence, but distances are still too long and the flow is not always obvious.',
                'fully_applied': 'Flow is simple and visual.\nLayout is designed to minimise flow but some areas are shared creating the need for layout changes during the week.',
                'sustained_improved': 'Flow is simple and visual.\nThere is a 24hr layout which is aligned to process sequence.\nMail movement distances are minimised, flow is clearly visible.',
                'title': 'Layout for Flow'
            },
            {
                'not_applied': 'No levelled workload scheduling.',
                'partially_applied': 'Workload Scheduling formalised but not driving towards continuous flow.',
                'fully_applied': 'Level Scheduling by production control to support flow.',
                'sustained_improved': 'Level Scheduling driving improvements;\nBatch size reduction\nMax level reduction.',
                'title': 'Levelled workload'
            },
            {
                'not_applied': 'There has been little or no analysis of mail arrivals and departure times.',
                'partially_applied': 'Analysis of the arrivals and departure profile has been completed but no action taken to improve the flow of mail in and out of the facility.',
                'fully_applied': 'There has been some improvement to the arrivals and departure profile but there is much more opportunity to improve flow.',
                'sustained_improved': 'Arrivals and departure times are levelled to optimise flow.',
                'title': 'Arrivals & Departures'
            }
        ],
        'stability': [
            {
                'not_applied': 'No Standard or Standard Work.\nStandards are not fit for purpose.\nProcess performed as operator sees fit - variation in approach.\nNo baseline for improvement.\nNo ability to measure competency against standard.',
                'partially_applied': 'Standards exist for process.\nVague content.\nWritten without input from team members.\nPockets of Standard Work.',
                'fully_applied': 'Standards are fit for purpose.\nStandard Work generated by teams.\nStandards agreed and built by teams.\nIndividual understanding of standard & competency to apply standard is tracked.',
                'sustained_improved': 'Standard Work sustained and improved.\nStandards used as a baseline for ' + this.ciTerm + '\nStandards used for problem solving.\nA regular review of standards is completed to minimise standards.\nCompetency is tracked.',
                'title': 'Standards &\nStandard Work'
            },
            {
                'not_applied': 'No process in place to confirm standards are being applied.',
                'partially_applied': 'Compliance checking process in place to confirm standards being applied, but process is not completed as specified or on an adhoc basis.',
                'fully_applied': 'Compliance checking process in place to confirm standards being applied, process is being completed but issues are not being effectively resolved.',
                'sustained_improved': 'Compliance checking process is in place and is part of the day to day activity, there is evidence that issues are being identified and resolved.',
                'title': 'Compliance Checking'
            },
            {
                'not_applied': 'No TPM strategy.\nMaintenance is reactive. No root cause of breakdowns occurs.\nNo Planned Maintenance, autonomous Maintenance, OEE.',
                'partially_applied': 'TPM elements exist.\nPlanned Maintenance strategy in place.\nMaintenance schedules exist but not consistently followed.\nAutonomous Maintenance in trial.',
                'fully_applied': 'TPM strategy in place.\nPlanned Maintenance in place.\nMaintenance schedules exist and are followed.\nAutonomous Maintenance in place.\nOEE used on key assets.\nStandards in place to support TPM.',
                'sustained_improved': 'Full TPM strategy embedded.\nPlanned Maintenance\nAutonomous Maintenance\nOEE\nEarly Equipment Management\nEffective Training.',
                'title': 'Total Productive Maintenance (TPM)'
            },
            {
                'not_applied': 'Operators / teams are not involved in 5S / workplace organisation activity.',
                'partially_applied': 'Some operators have limited role to keep area clean or teams recognise value of workplace organisation.',
                'fully_applied': 'All operators have recognised ownership of 5S and are involved in 5S audit.\nSustainment is limited.',
                'sustained_improved': 'Workplace organisation activity is sustained - operators have been involved in defining standards for their local workplace.\nLocal team manages most activity through visual management.',
                'title': 'Workplace Organisation'
            },
            {
                'not_applied': 'Changeover uncontrolled.\nNot tracked.\nNo standard exists.',
                'partially_applied': 'Changeover times are tracked and charted and set up instructions standardised.\nSelective improvements have taken place, but little or no effect on changeover time.',
                'fully_applied': 'Changeover reduction is actively pursued with detailed analysis by the teams and improvement actions.',
                'sustained_improved': 'Changeover is minimised and optimised.',
                'title': 'Quick Changeover (QCO)'
            }
        ],
        'right_first_time': [
            {
                'not_applied': 'No Andon / Escalation process.\nLittle or no evidence of issues being escalated.\nProduct/process will not stop for poor quality.',
                'partially_applied': 'Informal andon applied.\nOperators can stop process however no escalation formalised.',
                'fully_applied': 'Andon used to stop the process.\nFormalised process for the operators to escalate issues to leadership.',
                'sustained_improved': 'Andon used for ' + this.continuousImprovementSingle + '.\nAndon data feedback loop to inform problem solving and ' + this.continuousImprovementSingle + ' activities.',
                'title': 'Andon / Escalation'
            },
            {
                'not_applied': 'Adhoc response to delays and problems.',
                'partially_applied': 'There is a system in place to communicate and escalate problems and to allocate resource to resolve problems, but delays to the customer still occur.',
                'fully_applied': 'There is a process in place to communicate and escalate problems.\nContingency plans are in place to cover known issues to ensure problems can be responded to so that the customer is rarely affected.',
                'sustained_improved': 'We are able to respond to problems at all stages of the value stream so that the customer is not affected.',
                'title': 'Response to Delays & Issues'
            },
            {
                'not_applied': 'No Quality Loops.\nNo customer protection loop for quality, no inspection.',
                'partially_applied': 'End of Line Inspection.\nQuality is left to the inspector at end of line.',
                'fully_applied': 'Quality Loops in place along the value stream to feedback issues to the preceding processes.',
                'sustained_improved': 'Quality loops linked to Andon.\nQuality Loops in place along the value stream to feedback issues to the preceding processes.',
                'title': 'Quality Loops'
            },
            {
                'not_applied': 'Error proofing concept not applied.',
                'partially_applied': 'Error proofing in Standards.\nOver-inspection built in to procedures to mitigate concerns.',
                'fully_applied': 'Error proofing concept used as part of problem solving.\nEliminates, prevents and detects errors.',
                'sustained_improved': 'Error proofing concept applied to support ' + this.continuousImprovementSingle + '.\nSupports andon and flow.\nFeedback to Early Equipment Management and Future Design.',
                'title': 'Error Proofing'
            }
        ],
        'continuous_improvement': [
            {
                'not_applied': 'Teams not involved in problem solving\nNo Hourly count\nNo data collection\nNo pareto\nNo ' + this.cslipPluralTerm + ' or ' + this.cslipPluralTerm +
                    ' not \'live\'.',
                'partially_applied': 'Teams engaged in problem solving\nHourly count in place\nData collection of losses\nPareto built by team\n' + this.cslipPluralTerm + ' \'live\' and being used by the team with support',
                'fully_applied': 'Problem solving follows a systematic, formalised approach.\nOutputs are captured and assessed for read-across to other areas.\nTeams are able to escalate problems when management support is required.',
                'sustained_improved': 'All people in area trained in basic problem solving.\nEvidence of appropriate problem solving tools in use.\nActions in place to improve skill.',
                'title': 'Problem Solving'
            },
            {
                'not_applied': 'Teams not involved in ' + this.continuousImprovementSingle + '\nNo innovations raised\nNo ' + this.ciTermPlural + '\nNo breakthrough workshops',
                'partially_applied': 'Teams involved in ' + this.continuousImprovementSingle + '\nInnovations being raised in Info Centre\n' + this.ciTermPlural + ' being used by the team with support',
                'fully_applied': 'Teams engaged in ' + this.continuousImprovementSingle + '\n50% of ideas are implemented by the team with support\nTeams have ability to escalate ideas for support.',
                'sustained_improved': 'Teams leading ' + this.continuousImprovementSingle + ' autonomously\nInnovation hoppers\n' + this.ciTerm + ' completion\nBreakthrough Workshops\nRegular ' + this.ciTerm + ' meetings held by teams',
                'title': this.continuousImprovementSingle
            },
            {
                'not_applied': 'No plan of improvement.\nNo plan to improve the Value Stream.',
                'partially_applied': 'Isolated improvement plans in place.\nImprovement plans in place but limited to local objectives.',
                'fully_applied': 'Rolling plans in place.\nImprovement tasks are tracked and reported.\nNo integration with strategy deployment.',
                'sustained_improved': 'Transparent 90 Day Plans at all levels.\n90 Day plans tracked and measured across the organisation.\nStrategy deployment built in.\nFuture State implementation measured.',
                'title': 'Improvement Plans'
            },
            {
                'not_applied': 'There is little or no evidence of sharing good practice.',
                'partially_applied': 'There is a clear system/process of seeking and sharing good practice (e.g. case studies / sharing meetings), but little or no evidence of this being used.',
                'fully_applied': 'There is a clear system of seeking and sharing good practice (e.g. case studies / sharing meetings) which is being used and managed.',
                'sustained_improved': 'There is a clear system of seeking and sharing good practice.\nThere is evidence of practical implementation resulting from this process.',
                'title': 'Sharing Good Practice'
            }
        ],
        'strategy_deployment': [
            {
                'not_applied': 'No vision for the facility team.',
                'partially_applied': 'A vision is available.\nDocument for management team.\nCan be found posted in communication areas / portal / newsletters.',
                'fully_applied': 'Vision can be acted upon.\nIndividuals understand what needs to be done and their contribution to the vision.',
                'sustained_improved': 'Vision is deployed and agreed with teams.\nVision can be measured in action.\nInput from all levels to the plan is common practice.',
                'title': 'Vision'
            },
            {
                'not_applied': 'No prioritisation or objectives in place.',
                'partially_applied': 'Objectives are cascaded from the leadership team to teams and individuals.\nTeams measured on delivery of objectives.',
                'fully_applied': 'Strategy is deployed with annual objectives to meet the strategic plan.\nX tables are in place to lock in deliverables.\nAny team member can see their contribution to the deliverables and performance of the team.',
                'sustained_improved': 'Strategy is deployed and agreed with teams.\nAll teams have been involved in devising the implementation plan.\nResource commitments are clear.\nRoles are clear.',
                'title': 'Objectives & Metrics'
            },
            {
                'not_applied': 'No 90 day plans.',
                'partially_applied': 'Implementation plan available.\nPlans limited to facility manager level.',
                'fully_applied': '90 Day Plans are used by the facility manager level with front line teams.\nSynchronised 90 Day plans in place.',
                'sustained_improved': '90 Day Plans used by all.\nLevel 1s using 2 week window plans.\nLevel 2-3 using 90 Day Plans.\n2-5 Year Plan driving objectives and delivery.\nPDCA review of 90 Days at macro level.',
                'title': '90 Day Plans'
            },
            {
                'not_applied': 'No information centres.\nNo KPI\'s in place.\nNo ' + this.cslipPluralTerm + ' / ' + this.ciTermPlural + '.\nNo regular reviews.',
                'partially_applied': 'Information Centres in place but not aligned.\nKPIs in place for process.\nLocal issues tracked.\nSome ' + this.cslipPluralTerm + ' / ' + this.ciTermPlural + ' are in use.\nRegular reviews take place but not all key stakeholders are present.',
                'fully_applied': 'Information Centres in place and aligned.\nPolicy Deployment section with 90 Day Plan being tracked.\nKPIs in place for process.\n' + this.cslipPluralTerm + ' / ' + this.ciTermPlural + ' in use for all issues.\nRegular reviews take place with all key stakeholders present.',
                'sustained_improved': 'Information Centres are driving performance and ' + this.ciTerm + '.\nLeaders driving PDCA every 90 Days to improve and review deployment.\nLong term plans are shared.\nTeams and key stakeholders are engaged with the information centre.',
                'title': 'Information Centres'
            }
        ],
        'future_proofing': [
            {
                'not_applied': 'No FMEA.\nNo process to capture risks to process and customer using FMEA.',
                'partially_applied': 'FMEA process used in some areas.\nFMEAs used in areas but not a formal process for the facility.',
                'fully_applied': 'FMEAS in place for all Value Streams\nDesign FMEAs\nProcess FMEAs.\nHigh RPNs identified and mitigated.\nGood Standard Work in place to support FMEAs.',
                'sustained_improved': 'FMEAs used for ' + this.continuousImprovementSingle + '\nDesign and Process FMEAs used regularly by teams to tackle high RPNs and courses of action for improvement.',
                'title': 'Failure Modes & Effects Analysis (FMEA)'
            },
            {
                'not_applied': 'NO NPI / DFSS\nCopy, paste of production processes\nOperating cost with DFSS not factored - project implementation cost main driver\nVOC not factored\nProcess capability not factored',
                'partially_applied': 'No formal, gated approach\nOEM discussions led predominantly on price but evidence of production requirements factored in\nProjects factor in production feedback',
                'fully_applied': 'All projects follow NPI/DFSS process',
                'sustained_improved': 'NPI / DFSS used for ' + this.continuousImprovementSingle + '\nFeedback loops to OEMs for improvement in future asset acquisitions\nEarly Equipment Management formalised with NPI',
                'title': 'New Product Introduction (NPI) /\nDesign for Six Sigma (DFSS)'
            },
            {
                'not_applied': 'No Modular Design.\nDesign of process or equipment does not factor in maintenance or process/manufacturing requirements.',
                'partially_applied': 'Intentionally Blank',
                'fully_applied': 'Modular Design applied.\nAll future investments in assets factor for:\nEase of maintenance\nEase of production\nEase of mobility',
                'sustained_improved': 'Modular Design for ' + this.continuousImprovementSingle + '.\nFeedback loops to OEMs for improvement in future asset acquisitions.\nEarly Equipment Management.',
                'title': 'Modular Design'
            },
            {
                'not_applied': 'No change management process exists\nNo change management process followed',
                'partially_applied': 'Change management process exists\nChange management process used in some areas.',
                'fully_applied': 'Change management process exists and is followed in all areas.',
                'sustained_improved': 'Change management process is used for ' + this.continuousImprovementSingle + '.',
                'title': 'Change Management'
            }
        ]
    };

    //
    getSection(section_name) {
        return this.roadmapData[section_name];
    }

    //
    getSegment(section_name, segment_index) {
        return this.roadmapData[section_name][segment_index];
    }

    //
    getLevel(section_name, segment_index, level_name) {
        return this.roadmapData[section_name][segment_index][level_name];
    }

    getButtonTitles() {
        let buttonTitles = [
            {
                id: 'ci_leadership',
                title: '' + this.ciTerm + ' Leadership'
            },
            {
                id: 'flow',
                title: 'Flow'
            },
            {
                id: 'stability',
                title: 'Stability'
            },
            {
                id: 'right_first_time',
                title: 'Right First Time'
            },
            {
                id: 'continuous_improvement',
                title: this.continuousImprovementSingle
            },
            {
                id: 'strategy_deployment',
                title: 'Strategy Deployment'
            },
            {
                id: 'future_proofing',
                title: 'Future Proofing'
            },

        ];

        return buttonTitles;
    }
}
