import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    items = [];

    constructor() {
        this.items = [];
    }

    setItems(passedItems) {
        this.items = passedItems;
    }

    filterItems(searchTerm) {
        if (searchTerm === '') {
            return this.items;
        }
        return this.items.filter(item => {
            return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        });
    }
}
