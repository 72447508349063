import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {VideoJsComponent} from '../../components/videojs/videojs.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule],
    declarations: [VideoJsComponent],
    exports: [VideoJsComponent]

})
export class VideoJsSharedModule {
}
