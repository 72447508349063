import { Component, NgZone, OnInit } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthenticationService } from './_services/authentication.service';
import { RouteProvider } from './_services/utils/route.provider';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './_services/auth.interceptor';
import { CustomTermsProvider } from './_services/utils/customTerms.provider';
import { map } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Observable, Observer, merge } from 'rxjs';
import { Network } from '@ionic-native/network/ngx';
import { AlertService } from './_services/alert.service';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    currentRouteNotLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public router: Router,
        private routeHandler: RouteProvider,
        private swUpdate: SwUpdate,
        private authenticationService: AuthenticationService,
        private interceptor: AuthInterceptor,
        private cookies: CookieService,
        private customTerms: CustomTermsProvider,
        private ngZone: NgZone,
        private network: Network,
        private alertService: AlertService
    ) {
        if (window.location.href.includes('reset-password/')) {
            routeHandler.setLocationRoute(window.location.href);
        }
        let previousState = true;
        if (!this.platform.is('cordova')) {
            this.isOnline().subscribe(async res => {
                if (res) {
                    if (previousState != res) { // show it only if connection dropped previously
                        await this.alertService.setNetworkAlert('Internet connection established', 'Connection', 'success')
                    }
                }
                if (!res) {
                    await this.alertService.setNetworkAlert('No internet connection is detected', 'Connection')
                }
                previousState = res

            })
        }
        let previousMobileNetworkState = 'connected'
        if (this.platform.is('cordova')) {

            this.network.onChange().subscribe(async (options) => {

                if (options == 'connected') {
                    if (previousMobileNetworkState != options) { // show it only if connection dropped previously
                        await this.alertService.setNetworkAlert('Internet connection established', 'Connection', 'success')
                    }
                } else {
                    await this.alertService.setNetworkAlert('No internet connection is detected', 'Connection')
                }
                previousMobileNetworkState = options
            });
        }
        router.events
            .subscribe(event => {
                if (event instanceof NavigationEnd) {
                    routeHandler.setUrlRoute(event.url);
                    this.currentRouteNotLogin.next(!event.url.includes('login'));
                    this.ngZone.run(() => {
                        this.router.navigateByUrl(event.url);
                    });

                }
            });
        customTerms.init();
        this.initializeApp();
        //
        swUpdate.available.subscribe(event => {
            swUpdate.activateUpdate().then(() => document.location.reload());
        });
    }

    initializeApp() {

        this.cookies.set('rma-interaction', String(new Date().getTime()));

        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });

        this.checkLogin();

    }


    isOnline() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }


    checkLogin() {
        if (this.currentRouteNotLogin.getValue()) {
            if (this.routeHandler.getLocationRoute()) {

            } else {
                this.router.navigate([this.routeHandler.getUrlRoute()]);
            }
            this.interceptor.checkLogin().add(
                this.authenticationService.authState.subscribe(stateAuth => {
                    if (stateAuth) {
                        if (this.routeHandler.getLocationRoute()) {
                        } else {
                            this.router.navigate([this.routeHandler.getUrlRoute()]);
                        }
                    } else {
                        if (!this.authenticationService.refreshingState.getValue()) {
                            this.authenticationService.logout();
                        }
                    }
                })
            );
        }
    }

    registerTime() {
        const time = new Date().getTime();
        this.checkInteraction(time, this.cookies.get('rma-interaction'));
        this.cookies.set('rma-interaction', String(time));

    }

    checkInteraction(timeNow, storedTime) {
        const now = Number(timeNow);
        const storedNumber = Number(storedTime);
        const twoHours = 7200000;
        if (now - storedNumber > twoHours) {
            this.checkLogin();
        }
    }
}
