import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {AuthenticationService} from './authentication.service';
import {RouteProvider} from './utils/route.provider';

@Injectable({
    providedIn: 'root'
})
export class LoginGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private routeHandler: RouteProvider, private alertCtrl: AlertController) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (this.auth.isAuthenticated() && this.auth.isLoggedIn()) {
            this.alertCtrl.create({
                header: 'Logged in',
                message: 'You are not allowed to access that page. Please log out first.',
                buttons: ['OK']
            }).then(alert => alert.present()).then(r => this.router.navigate(['home']));

            return false;
        }

        if (this.routeHandler.getLocationRoute()) {
            this.router.navigate([this.routeHandler.getLocationRoute()]);
            return true;
        }

        return true;
    }
}
