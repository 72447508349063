<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-buttons slot="start" (click)="goRoot()">
				<ion-icon class="home-icon" name="ios-home"></ion-icon>
			</ion-buttons>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
	<ion-button class="highlight-btn" expand="block" (click)="goParent()">
		{{parentName}} videos
	</ion-button>

	<div>
		<ion-row>
			<h4 class="highlight-text">{{title}}
				<ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>
			</h4>
		</ion-row>
	</div>
	<div *ngIf="!isProcessed" class="text-center">
		<p class="processing">
			Processing video
		</p>
	</div>
	<!--	video-js playere here-->
	<div *ngIf="videoStream">
		<app-video-js [options]='{ autoplay: true, fluid:true,controls: true,html5 : {
            hls: {
                overrideNative: !isSafariIOS
            },
            nativeAudioTracks: isSafariIOS,
            nativeVideoTracks: isSafariIOS
        }, sources: [{ src: videoStream,   type: "application/x-mpegURL" }]}'></app-video-js>

	</div>

</ion-content>
