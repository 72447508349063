import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {AuthenticationService} from './authentication.service';
import {RouteProvider} from './utils/route.provider';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private routeHandler: RouteProvider, private alertCtrl: AlertController) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        if (route.routeConfig.path === 'reset-password/:token') {
            this.routeHandler.setRoute(route);
            return true;
        }
        if (!this.auth.isAuthenticated()) {
            this.alertCtrl.create({
                header: 'Unauthorized',
                message: 'You are not allowed to access that page. Please log in first.',
                buttons: ['OK']
            }).then(alert => alert.present()).then(r => {
                // @ts-ignore
                const idValid = !isNaN(Number(route.params.id));

                if (route.routeConfig.path === 'standards/:id' && idValid) {
                    this.routeHandler.setRoute(route);
                }
                this.router.navigate(['login']);
            });
            return false;
        }

        return true;
    }
}
