import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
    selector: 'app-video-js',
    templateUrl: './videojs.component.html',
    styleUrls: ['./videojs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoJsComponent implements OnInit, OnDestroy {
    @ViewChild('target', { static: true }) target: ElementRef;
    // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html

    @Input() options: {
        fluid: boolean,
        aspectRatio: string,
        sources: {
            src: string,
            type: string,
        }[],
    };
    player: videojs.Player;

    constructor(
        public cookies: CookieService, private elementRef: ElementRef,
        private storageService: StorageService
    ) {
    }

    ngOnInit() {
        this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
            // console.log('onPlayerReady', this);
        });
        this.player.src({ src: this.options.sources[0].src, type: this.options.sources[0].type });

        videojs.Hls.xhr.beforeRequest = (options) => {
            options.headers = { Authorization: `BEARER ${this.cookies.get('rma-token')}` };
            // options.headers = { Authorization: `BEARER ${this.storageService.get('rma-token')}` };

            return options;
        };
        if (this.player) {
            this.player.play();
        }
    }

    isSafari() {
        const nav = window.navigator;
        const ua = nav.userAgent;

        if (ua.indexOf('iPhone') !=
            -1 && ua.indexOf('Safari') !=
            -1 && ua.indexOf('CriOS') ==
            -1 && ua.indexOf('FxiOS') ==
            -1) {
            return false;
        }
        return true;
    }

    ngOnDestroy() {
        // destroy player
        if (this.player) {
            this.player.dispose();
        }
    }
}
