<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-buttons slot="start">
				<ion-icon class="home-icon" name="ios-home" routerLink="/home" routerDirection="root"></ion-icon>
			</ion-buttons>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
	<ion-button class="highlight-btn" expand="block" routerLink={{parentPath}} routerDirection="back">
		{{parentName}}
	</ion-button>


	<div *ngIf="videos">
		<ion-list *ngFor="let video of videos">
			<ion-list-header>
				<h4 class="highlight-text">{{video.name}}</h4>
			</ion-list-header>
			<ion-item lines="none">

				<app-gif-preview path="{{url+video.thumbnail}}" parentPath="{{parentPath}}"
								 id="{{video.id}}" extraClass="small-list" mainParent=""
								 [title]="video.name"></app-gif-preview>
			</ion-item>
		</ion-list>

	</div>
</ion-content>
