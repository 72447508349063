import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentApiService} from '../../../_services/content.api.service';
import {CategoryProvider} from '../../../_services/utils/category.provider';
import {environment} from '../../../../environments/environment';
import {interval} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-video-page',
    templateUrl: './video.page.component.html',
    styleUrls: ['./video.page.component.scss'],
})
export class VideoPageComponent implements OnInit {

    parentName: string;
    parentPath: string;
    url;
    category;
    path;
    title;
    id;
    mainParent = '';
    isLoading = false;
    videoStream = null;
    overrideNative = false; // needed true for android..
    isProcessed = true;
    extraOption = {};
    headers: any;
    isSafariIOS = false;

    // tslint:disable-next-line:max-line-length
    constructor(private route: ActivatedRoute, private contentApi: ContentApiService, private  http: HttpClient, private router: Router, private globalCategories: CategoryProvider) {
        this.route.params.subscribe(params => {
            const categoryObj = globalCategories.getCategoryBySlug(params.category);
            this.category = categoryObj.slug;
            this.mainParent = params.parent;
            this.parentPath = params.parent ? params.parent + '/videos' : categoryObj.route + '/videos';
            this.parentName = params.parent ? params.parent : categoryObj.name;
            this.id = params.id;
        });
        this.isSafariIOS = false;

    }


    ngOnInit() {
        this.getVideo();

    }

    getVideo() {
        this.contentApi.getVideoSingle(this.id).subscribe((video) => {
            this.isLoading = true;
            // @ts-ignore
            this.title = video.name;
            // @ts-ignore
            this.videoStream = environment.apiUrlClean + video.stream;
            // @ts-ignore
            this.isLoading = false;
            // @ts-ignore
            this.isProcessed = video.isProcessed;
            // @ts-ignore
            if (!video.isProcessed) {
                this.loadProcessingVideo(video);
            }
        });

    }

    loadProcessingVideo(video) {
        const videoInterval = interval(5000)
            .pipe(
                startWith(0),
                switchMap(() => this.contentApi.getVideoSingle(video.id))
            )
            .subscribe(res => {
                // @ts-ignore
                this.isProcessed = res.isProcessed;
                // @ts-ignore
                if (res.isProcessed) {
                    videoInterval.unsubscribe();
                }
            });

    }

    goRoot() {
        this.router.navigateByUrl('/home');

    }

    goParent() {
        this.router.navigateByUrl(this.parentPath);

    }

    blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    isSafari() {
        const nav = window.navigator;
        const ua = nav.userAgent;

        // tslint:disable-next-line:triple-equals
        if (ua.indexOf('iPhone') !=
            // tslint:disable-next-line:triple-equals
            -1 && ua.indexOf('Safari') !=
            // tslint:disable-next-line:triple-equals
            -1 && ua.indexOf('CriOS') ==
            // tslint:disable-next-line:triple-equals
            -1 && ua.indexOf('FxiOS') ==
            -1) {
            return false;
        }
        return true;
    }

}
