import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs';
import {forkJoin} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class TiersApiService {

    url = environment.apiUrl;


    constructor(
        private http: HttpClient) {
    }


    getTiers() {
        return this.http
            .get(this.url + 'api/tiers');
    }

    getTiersList() {
        return this.http
            .get(this.url + 'api/tiers/list');
    }

    getTierById(id) {
        return this.http
            .get(this.url + 'api/tiers' + id);
    }

    addTier(name) {
        return this.http
            .post(this.url + 'api/tiers', {name: name});
    }

    editTierName(id, name) {
        return this.http.patch(this.url + 'api/tiers/' + id + '/name', {name: name});

    }

    editTierLevel(id, level) {
        return this.http.patch(this.url + 'api/tiers/' + id + '/level', {level: level});

    }

    editTier(data, id) {
        return forkJoin([
            this.http.patch(this.url + 'api/tiers/' + id + '/name', {name: data.name}),
            this.http.patch(this.url + 'api/tiers/' + id + '/level', {level: data.level})]);
    }

}
