<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-icon class="home-icon" name="ios-home" [routerLink]="['../../',parentPath]"
					  routerDirection="root"></ion-icon>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [scrollEvents]="true">
	<div>
		<h1 class="highlight-text">{{title}}</h1>
	</div>

	<form [formGroup]="qsForm">
		<ion-list>

			<div *ngIf="!allowMultiSelection" formArrayName="questions">
<!--				Single Choice:-->
				<div *ngFor="let qs of qsForm.get('questions')['controls']; let i=index" [formGroupName]="i">
					<ion-radio-group formControlName="choice">
						<ion-list-header>
							<ion-label>{{qs.controls.text.value}}</ion-label>
						</ion-list-header>
						<ion-item *ngFor="let answer of qs.get('answers')['value']; let j=index" lines="none">
							<ion-label class="non-truncated">{{answer.text}}</ion-label>
							<ion-radio slot="start" [value]="answer.id" (click)="selectRadio(i,answer.id)"></ion-radio>
						</ion-item>
					</ion-radio-group>
				</div>
			</div>

			<div *ngIf="allowMultiSelection" formArrayName="questionsMultiple">
<!--				Multiple Choices:-->
				<div *ngFor="let qs2 of qsForm.get('questionsMultiple')['controls']; let k=index" [formGroupName]="k">
					<ion-radio-group>
						<ion-list-header>
							<ion-label>{{qs2.controls.text.value}}</ion-label>
						</ion-list-header>

						<ion-item *ngFor="let answer of qs2.get('answers')['value']; let h=index">

							<ion-label>{{answer.text}}</ion-label>
							<ion-checkbox slot="start" [value]="answer.id"
										  (click)="selectChecked(k,answer.id)"></ion-checkbox>
						</ion-item>
					</ion-radio-group>
				</div>
			</div>
			<ion-button [ngClass]="saveAllowed?'highlight-btn':'highlight-btn disabled'" expand="block" (click)="submitQuestions()"
						type="submit">
				Save
			</ion-button>

		</ion-list>
	</form>
</ion-content>
