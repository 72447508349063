import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {GifPreviewComponent} from '../../components/gif-preview/gif-preview.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule],
    declarations: [GifPreviewComponent],
    exports: [GifPreviewComponent]

})
export class GifPreviewSharedModule {
}
