export const CREATE_ID = 'create';
export const POPUP_COMMAND_DELETE = 'Delete';
export const POPUP_COMMAND_EDIT = 'edit';
export const API_DELETE = 'delete';
export const API_EDIT = 'edit';
export const API_ADD = 'add';
export const API_INIT = 'initial';

// API URLS
export const API_URL = 'https://api-staging.geneowebapp.com/';
export const API_URL_CLEAN = 'https://api-staging.geneowebapp.com';
// production environment
export const API_URL_PRODUCTION = 'https://royalmail.geneowebapp.com/';
export const API_URL_PRODUCTION_CLEAN = 'https://royalmail.geneowebapp.com';
// // local dev environment
// // @ts-ignore
// export const API_URL_DEV = 'http://127.0.0.1:8000/';
// // @ts-ignore
// export const API_URL_DEV_CLEAN = 'http://127.0.0.1:8000';
// dev environment
// @ts-ignore
export const API_URL_DEV = 'https://dev-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_DEV_CLEAN = 'https://dev-api.geneowebapp.com';
// staging environment
// @ts-ignore
export const API_URL_STAGING = 'https://api-staging.geneowebapp.com/';
// @ts-ignore
export const API_URL_STAGING_CLEAN = 'https://api-staging.geneowebapp.com';
// local environment
// @ts-ignore
export const API_URL_LOCAL = 'https://api-staging.geneowebapp.com/';
// @ts-ignore
export const API_URL_LOCAL_CLEAN = 'https://api-staging.geneowebapp.com';
// @ts-ignore
export const API_URL_DEMO = 'https://api-demo.geneowebapp.com/';
// @ts-ignore
export const API_URL_DEMO_CLEAN = 'https://api-demo.geneowebapp.com';
// @ts-ignore
export const API_URL_GENIE = 'https://genie-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_GENIE_CLEAN = 'https://genie-api.geneowebapp.com';
// @ts-ignore
export const API_URL_KPMG = 'https://api-kpmg.geneowebapp.com/';
// @ts-ignore
export const API_URL_KPMG_CLEAN = 'https://api-kpmg.geneowebapp.com';
// @ts-ignore
export const API_URL_BLUEBEAR = 'https://api-bluebear.geneowebapp.com/';
// @ts-ignore
export const API_URL_BLUEBEAR_CLEAN = 'https://api-bluebear.geneowebapp.com';
// @ts-ignore
export const API_URL_E6 = 'https://e6.geneowebapp.com/';
// @ts-ignore
export const API_URL_E6_CLEAN = 'https://e6.geneowebapp.com';
// @ts-ignore
export const API_URL_SOUTH32 = 'https://south32.geneowebapp.com/';
// @ts-ignore
export const API_URL_SOUTH32_CLEAN = 'https://south32.geneowebapp.com';
// @ts-ignore
export const API_URL_LOGANAIR = 'https://loganair.geneowebapp.com/';
// @ts-ignore
export const API_URL_LOGANAIR_CLEAN = 'https://loganair.geneowebapp.com';
// @ts-ignore
export const API_URL_WORKPLACE_COACHING = 'https://workplace-coaching.geneowebapp.com/';
// @ts-ignore
export const API_URL_WORKPLACE_COACHING_CLEAN = 'https://workplace-coaching.geneowebapp.com';
// @ts-ignore
export const API_URL_CONSULTANTS = 'https://consultants-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_CONSULTANTS_CLEAN = 'https://consultants-api.geneowebapp.com';
// @ts-ignore
export const API_URL_TEXECOM = 'https://texecom-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_TEXECOM_CLEAN = 'https://texecom-api.geneowebapp.com';
// @ts-ignore
export const API_URL_RYNAT = 'https://rynat-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_RYNAT_CLEAN = 'https://rynat-api.geneowebapp.com';
// @ts-ignore
export const API_URL_UMBRAGROUP = 'https://umbragroup-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_UMBRAGROUP_CLEAN = 'https://umbragroup-api.geneowebapp.com';
// @ts-ignore
export const API_URL_EGA = 'https://ega-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_EGA_CLEAN = 'https://ega-api.geneowebapp.com';


// @ts-ignore
export const API_URL_RAS = 'https://ras-api.geneowebapp.com/';
// @ts-ignore
export const API_URL_RAS_CLEAN = 'https://ras-api.geneowebapp.com';

export const TYPE_DEMO = 'DEMOGENEO';
export const TYPE_RM = 'ROYALMAIL';

export const GENEO_DARK_LOGO = './assets/icons/geneo/GENEO-icon-512-no-margin.png';
export const DEMO_LOGO_PATH = './assets/icons/geneo/GENEO_Logo_white_512.png';
export const RM_LOGO_PATH = './assets/icons/rm_logo.png';
export const RM_FAVICON_PATH = 'assets/icon/favicon_rm.png';
export const RM_APPLETOUCH_PATH = 'assets/icons/rm_logo.png';
export const DEMO_FAVICON_PATH = 'assets/icon/geneo-favicon-64.jpg';
export const DEMO_APPLETOUCH_PATH = 'assets/icon/geneo-icon-192.jpg';
export const DEMO_APP_TITLE = 'Geneo - Continuous Improvement';
export const RM_APP_TITLE = 'Royal Mail';
export const DEMO_APP_SUB_TITLE = 'Geneo - Continuous Improvement';
export const RM_APP_SUB_TITLE = 'Royal Mail - Improvement App';
export const DEMO_APP_HIGHLIGHT_COLOUR = '#028484';
export const RM_APP_HIGHLIGHT_COLOUR = '#ed1c24';

export const ANALYTICS_WORKPLACE_COACHING = 'workplace-coaching';
export const ANALYTICS_WORK_AREA_LOCATION  = 'work-area-location';
