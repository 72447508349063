import {Injectable} from '@angular/core';
import {Crypt} from './utils/crypt';
import {Storage} from '@ionic/storage';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class StorageService {

    constructor() {
    }

    get(key) {
        return localStorage.getItem(key);
    }

    set(key, val) {
        localStorage.setItem(key, val);
    }

    deleteAll() {
        localStorage.clear();
    }
}
