import {Injectable} from '@angular/core';
import {GlobalApiService} from '../global.api.service';
import {CustomTermsProvider} from './customTerms.provider';

@Injectable()
export class CategoryProvider {
    categories = [];
    ciTerm = this.customTerms.getCIterm();
    nameCoachingCI = 'Coaching ' + this.ciTerm;
    nameLearnMoreAboutCI = 'Learn More About ' + this.ciTerm;

    constructor(private globalApi: GlobalApiService, private customTerms: CustomTermsProvider) {
    }

    init() {
        return this.globalApi.getCategories().subscribe(res => {
            this.categories = Categories(res, this.nameLearnMoreAboutCI, this.nameCoachingCI);
        });
    }

    getCategories() {
        return this.categories;
    }

    getCategoryBySlug(categorySlug) {
        return this.categories.find(category => category.slug === categorySlug);
    }
}

enum CategoryName {
    // @ts-ignore
    Coaching_CI = 'Coaching CI',
    Leadership_Actions = 'Leadership Actions',
    Learn_More_About_CI = 'Learn More About CI',
    Operating_System = 'Operating System',
    Road_Maps = 'Road Maps',
    Seven_Wastes = 'Seven Wastes'
}

export enum CategorySlug {
    Coaching_CI = 'coaching-ci',
    Leadership_Actions = 'leadership-actions',
    Learn_More_About_CI = 'learn-more-about-ci',
    Operating_System = 'operating-system',
    Road_Maps = 'road-maps',
    Seven_Wastes = 'seven-wastes'
}

export function Categories(init = null, nameLearnMoreAboutCI?, nameCoachingCI?) {

    const predefinedCategories = [{
        name: nameCoachingCI,
        slug: CategorySlug.Coaching_CI,
        route: '/coaching-ci',
        id: null,
        quizzes: [],
        documents: [],
        videos: []
    }, {
        name: CategoryName.Leadership_Actions,
        slug: CategorySlug.Leadership_Actions,
        route: '/leadership-actions',
        id: null,
        quizzes: [],
        documents: [],
        videos: []
    }, {
        name: nameLearnMoreAboutCI,
        slug: CategorySlug.Learn_More_About_CI,
        route: '/learn-more-about-ci',
        id: null,
        quizzes: [],
        documents: [],
        videos: []
    }, {
        name: CategoryName.Operating_System,
        slug: CategorySlug.Operating_System,
        route: '/operating-system',
        id: null,
        quizzes: [],
        documents: [],
        videos: []
    }, {
        name: CategoryName.Road_Maps,
        slug: CategorySlug.Road_Maps,
        route: '/road-maps',
        id: null,
        quizzes: [],
        documents: [],
        videos: []
    }, {
        name: CategoryName.Seven_Wastes,
        slug: CategorySlug.Seven_Wastes,
        route: '/seven-wastes',
        id: null,
        quizzes: [],
        documents: [],
        videos: []
    }];
    if (init) {
        init.forEach(value => {
            predefinedCategories.find(category => category.slug === value.slug).id = value.id;
        });
    }
    return predefinedCategories;
}


export function categoryRoute(categorySlug) {
    const route = Categories().find(category => category.slug === categorySlug).route;
    return route ? route : '/home';
}

export function categoryName(categorySlug) {
    const name = Categories().find(category => category.slug === categorySlug).name;
    return name ? name : 'Home';
}
