import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {AuthenticationService} from './authentication.service';
import {RouteProvider} from './utils/route.provider';

@Injectable({
    providedIn: 'root'
})
export class HomeGuardService implements CanActivate {
    constructor(private router: Router, private routeHandler: RouteProvider, private auth: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.auth.authState.subscribe(stateAuth => {
            if (stateAuth) {
                if (this.auth.isAuthenticated() && this.auth.isLoggedIn()) {
                    this.router.navigate(['home']);
                } else {
                    this.auth.logout();
                    this.router.navigate(['login']);
                }
                return true;
            } else {
                if (route.routeConfig.path === 'reset-password/:token') {
                    this.routeHandler.setRoute(route);
                    return true;
                }
                this.router.navigate(['login']);
            }
        });
        return true;
    }
}
