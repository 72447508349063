import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    currentNetworkAlert = null;

    clicks = []

    constructor(
        public alertController: AlertController
    ) {

    }


    getErrorMsg(err) {
        let errMsg;
        if (err.error) {
            if (err.error.message) {
                errMsg = err.error.message
            }
        }
        // || err.status == 400
        if (!errMsg) {
            errMsg = 'A network error occurred while communicating with the server. Please try again later.';
        }
        return errMsg;
    }

    async presentAlert(msg, title?, callback?) {
        const alert = await this.alertController.create({
            header: title ? title : 'Something went wrong',
            message: msg,
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                    }
                }
            ]
        });

        await alert.present();
    }

    clearNetworkAlert() {
        if (this.currentNetworkAlert) {
            this.currentNetworkAlert.dismiss();
            this.currentNetworkAlert = null;
        }
        let array: HTMLCollectionOf<Element> | any[] = []
        const alertDOM = document.getElementsByTagName('ion-alert')
        array = alertDOM.length > 0 ? Array.from(alertDOM) : []
        if (alertDOM.length != 0) {
            this.currentNetworkAlert?.dismiss();
            this.currentNetworkAlert = null;

        }
        array.forEach(alert => {
            alert.remove()
        })
    }

    async setNetworkAlert(msg, title, type?) { // its specific to network alerts only
        this.clearNetworkAlert()
        let options = {
            header: title ? title : 'Connection' + this.currentNetworkAlert.length,
            message: msg,
            cssClass: 'alert-no-buttons',
            id: 'noConnectionAlert',
            backdropDismiss: false
        }
        if (type == 'success') {
            options.backdropDismiss = true
            options.cssClass = ''
            // @ts-ignore
            options.buttons = [{
                text: 'OK',
                handler: () => {
                }
            }]
        }
        this.currentNetworkAlert = await this.alertController.create(options)
        await this.currentNetworkAlert.present().then(a => {
            let clicks = this.clicks
            let clear = () => this.clearNetworkAlert()
            document.addEventListener('click', function (e) {
                // @ts-ignore
                if (e.target && e.target.innerHTML == 'No internet connection is detected') {
                    let currentTime = new Date().getTime();

                    const minimumClickAmount = 300;
                    if (clicks.length == 0) {
                        clicks[0] = currentTime;
                        return;
                    }
                    if (currentTime - clicks[clicks.length - 1] <= minimumClickAmount) {
                        clicks[clicks.length] = currentTime;
                    } else {
                        clicks = [];
                    }
                    if (clicks.length == 10) {
                        console.log('Removing lost connection alert')
                        clicks = []
                        clear()
                    }
                }
            });
        })

    }
}
