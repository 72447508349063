import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {VideoPageComponent} from '../../components/video-page/video.page.component';
import {VideoJsSharedModule} from '../videojs/videojs.shared.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        VideoJsSharedModule,
    ],
    declarations: [VideoPageComponent],
    exports: [VideoPageComponent]

})
export class VideoPageSharedModule {
}
