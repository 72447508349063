import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private alertCtrl: AlertController) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!this.auth.isAdminLogged()) {
            this.alertCtrl.create({
                header: 'Unauthorized',
                message: 'You are not allowed to access that page.<br/>Ask Administrator for access',
                buttons: ['OK']
            }).then(alert => alert.present());

            return false;
        }

        return true;
    }
}
