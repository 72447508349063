import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContentApiService} from '../../../_services/content.api.service';
import {CategoryProvider} from '../../../_services/utils/category.provider';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-videos-list',
    templateUrl: './videos-list.component.html',
    styleUrls: ['./videos-list.component.scss'],
})
export class VideosListComponent implements OnInit {
    videos = null;
    category = '';
    parentPath = '';
    parentName = '';
    url = environment.apiUrlClean;

    constructor(private route: ActivatedRoute, private contentApi: ContentApiService, private globalCategories: CategoryProvider) {
        this.route.params.subscribe(params => {
            const categoryObj = globalCategories.getCategoryBySlug(params.category);
            this.category = categoryObj.slug;
            this.parentPath = categoryObj.route;
            this.parentName = categoryObj.name;
        });
    }

    ionViewWillEnter() {
        this.contentApi.getVideosByCategory(this.category).subscribe((data) => {
            this.videos = data;
        });
    }

    ngOnInit() {
    }
}
