import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertController, IonContent} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentApiService} from '../../../_services/content.api.service';

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
    @ViewChild(IonContent, {static: false}) content: IonContent;
    title = 'Quiz';
    allowMultiSelection = false;
    saveAllowed = false;
    parentPath = '/home';
    category;
    quizId;
    data;

    resultMessages = [
        {resultMin: 0, resultMax: 10, message: 'Oh boy, we need to get back to bootcamp!'},
        {resultMin: 11, resultMax: 25, message: 'Crikey, we need to hit the books'},
        {resultMin: 26, resultMax: 50, message: 'Well, we are half-way there.  Keep trying'},
        {resultMin: 51, resultMax: 90, message: 'Nice one!'},
        {resultMin: 91, resultMax: 100, message: 'You are a CI machine!'}
    ];

    qsForm: FormGroup;

    constructor(private fb: FormBuilder, public alertController: AlertController, private router: Router,
                private route: ActivatedRoute, private contentApi: ContentApiService) {
        this.qsForm = this.fb.group({
            questions: this.fb.array([
                this.initQuestion()
            ]),
            questionsMultiple: this.fb.array([
                this.initQuestion()
            ])
        });
        this.route.params.subscribe(params => {
            this.category = params.category;
            this.parentPath = 'quizzes';
            this.quizId = params.id;
        });
    }

    ngOnInit(): void {

        this.contentApi.getQuizById(this.quizId).subscribe(data => {
            this.data = data;
            // @ts-ignore
            this.title = data.title;
            this.setForm(data);
        });
    }

    setForm(data) {
        this.qsForm = this.fb.group({
            questions: this.setQuestion(data),
            questionsMultiple: this.setQuestion(data)
        });
    }

    initQuestion() {
        return this.fb.group({
            choice: '',
            text: '',
            id: '',
            answers: []
        });
    }

    setQuestion(data: any) {
        const arr = new FormArray([]);
        data.questions.forEach((q: any) => {
            arr.push(this.fb.group({
                choice: '',
                text: q.question,
                id: q.id,
                answers: this.setAnswers(q)
            }));
        });
        return arr;
    }


    setAnswers(x) {
        let arr = new FormArray([]);
        x.answers.forEach(y => {
            arr.push(this.fb.group({
                text: y.answer,
                id: y.id,
                is_correct: y.isCorrect
            }));
        });
        return arr;
    }

    selectChecked(questionIndex, value: any) {
        const formArray = this.qsForm.controls.questionsMultiple as FormArray;
        const formGroup = formArray.at(questionIndex) as FormGroup;
        const choice = formGroup.controls.choice;
        if (choice.value.search(value) === -1) {
            if (choice.value !== '') {
                choice.setValue(choice.value + ',' + value);
            } else {
                choice.setValue(value);
            }
        } else {
            const splitted = choice.value.split(',');
            const idx = splitted.indexOf(value, 0);
            splitted.splice(idx, 1);
            choice.setValue(splitted.toString());
        }
        this.validateSave(formArray);
    }

    selectRadio(questionIndex, value: any) {
        const formArray = this.qsForm.controls.questions as FormArray;
        const formGroup = formArray.at(questionIndex) as FormGroup;
        const choice = formGroup.controls.choice;
        choice.setValue(value);
        this.validateSave(formArray);
    }

    submitQuestions() {
        let submitData: any[] = [];
        if (!this.allowMultiSelection) {
            this.qsForm.value.questions.forEach((qu: any) => {
                submitData.push({
                    answers: qu.answers,
                    selected: qu.choice
                });
            });
        } else {
            this.qsForm.value.questionsMultiple.forEach((qu2: any) => {
                submitData.push({
                    answers: qu2.answers,
                    selected: qu2.choice
                });
            });
        }
        this.presentAlertConfirm(this.resultMessage(this.validateAnswers(submitData), submitData.length));
    }

    resultMessage(correctAnswers, questionsNumber) {
        let msg = 'Ups, something went wrong';
        const percentage = correctAnswers / questionsNumber * 100;
        this.resultMessages.forEach(result => {
            if (percentage >= result.resultMin && percentage <= result.resultMax) {
                msg = '<div><p><b>' + correctAnswers + '/' + questionsNumber + '</b></p>' + result.message + '<p></p></div>';
            }
        });
        return msg;
    }

    clearSelection() {
        this.setForm(this.data);
        this.saveAllowed = false;
    }

    async presentAlertConfirm(msg) {
        const alert = await this.alertController.create({
            header: 'Results',
            message: msg,
            buttons: [
                {
                    text: 'Try again',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        this.content.scrollToTop(500);
                        this.clearSelection();
                    }
                }, {
                    text: 'OK',
                    handler: () => {
                        this.clearSelection();
                        this.content.scrollToTop(1);
                        this.router.navigateByUrl('/' + this.category + '/quizzes');
                    }
                }
            ]
        });

        await alert.present();
    }

    validateSave(data) {
        this.saveAllowed = data.value.every((item) => item.choice != null);
    }

    validateAnswers(data) {
        let correctAnswers = 0;
        data.forEach((question) => {
            const splitted = question.selected.toString().split(',');
            question.answers.forEach(answer => {
                if ((answer.is_correct)) {
                    splitted.forEach(selection => {
                        if (selection == answer.id) {
                            correctAnswers += 1;
                        }
                    });

                }
            });

        });
        return correctAnswers;
    }

}



