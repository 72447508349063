import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RoadmapHandlersService } from './_services/roadmapHandlers.service';
import { AuthGuardService } from './_services/auth.guard.service';
import { AuthenticationService } from './_services/authentication.service';
import { IonicStorageModule } from '@ionic/storage';
import { CookieService } from 'ngx-cookie-service';
import { UserApiService } from './_services/user.api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Crypt } from './_services/utils/crypt';
import { QuizzesListComponent } from './shared/components/quizzes-list/quizzes-list.component';
import { ContentApiService } from './_services/content.api.service';
import { VideosListComponent } from './shared/components/videos-list/videos-list.component';
import { QuizComponent } from './shared/components/quiz/quiz.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './_services/auth.interceptor';
import { FileInputValueAccessor } from './shared/accessors/fileRequired.accessor';
import { GifPreviewSharedModule } from './shared/modules/gif-preview/gif-preview.shared.module';
import { VideoPageSharedModule } from './shared/modules/video-page/video.shared.module';
import { VideoJsSharedModule } from './shared/modules/videojs/videojs.shared.module';
import { GlobalApiService } from './_services/global.api.service';
import { CategoryProvider } from './_services/utils/category.provider';
import { RouteProvider } from './_services/utils/route.provider';
import { FilterService } from './_services/filter.service';

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';

// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { StorageService } from './_services/storage.service';
import { LoadingService } from './_services/loading.service';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {SettingsApiService} from './_services/settings.api.service';
import {TiersApiService} from './_services/tiers.api.service';
import {WasteImpactApiService} from './_services/waste-impact.api.service';
import {CustomTermsProvider} from './_services/utils/customTerms.provider';


@NgModule({
    declarations: [AppComponent, QuizzesListComponent, VideosListComponent, QuizComponent, FileInputValueAccessor],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        VideoJsSharedModule,
        VideoPageSharedModule,
        // PdfViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ReactiveFormsModule,
        GifPreviewSharedModule,
        // NgxExtendedPdfViewerModule,
        FontAwesomeModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        VideoPlayer,
        Network,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthGuardService,
        CookieService,
        UserApiService,
        TiersApiService,
        SettingsApiService,
        GlobalApiService,
        FilterService,
        RouteProvider,
        CategoryProvider,
        CustomTermsProvider,
        ContentApiService,
        AuthenticationService,
        WasteImpactApiService,
        Crypt,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        RoadmapHandlersService,
        File,
        FileOpener,
        FileTransfer,
        DocumentViewer,
        BarcodeScanner,
        StorageService,
        LoadingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, fab, far);
    }
}
