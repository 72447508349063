import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './_services/auth.guard.service';
import { AdminGuardService } from './_services/admin.guard.service';
import { LoginGuardService } from './_services/login.guard.service';
import { QuizzesListComponent } from './shared/components/quizzes-list/quizzes-list.component';
import { VideosListComponent } from './shared/components/videos-list/videos-list.component';
import { QuizComponent } from './shared/components/quiz/quiz.component';
import { VideoPageComponent } from './shared/components/video-page/video.page.component';
import { HomeGuardService } from './_services/home.guard.service';


const routes: Routes = [
    // {
    //   path: '',
    //   redirectTo: 'landing',
    //   pathMatch: 'full'
    // },
    {
        path: '',
        loadChildren: () => import('./main/main-welcome.module').then(m => m.MainWelcomePageModule),
        canActivate: [HomeGuardService]
    },

    {
        path: 'landing',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
        canActivate: [LoginGuardService]
    },
    {
        path: 'registration',
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule),
        canActivate: [LoginGuardService]
    },
    {
        path: 'register',
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule),
        canActivate: [LoginGuardService]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [AuthGuardService, AdminGuardService]
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'tiers',
        loadChildren: () => import('./tiers/tiers.module').then(m => m.TiersPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'waste-impact-types',
        loadChildren: () => import('./waste-impact-types/waste-impact-types.module').then(m => m.WasteImpactTypesPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: ':category/quizzes',
        component: QuizzesListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: ':category/videos',
        component: VideosListComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: ':category/videos/:id',
        component: VideoPageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: ':category/videos/:id/:parent',
        component: VideoPageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: ':category/quiz/:id',
        component: QuizComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'profile',
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfilePageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'reset-password/:token',
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
    },
    {
        // There's a bug that's preventing wild card routes to be lazy loaded (see: https://github.com/angular/angular/issues/13848)
        // That's why the Error page should be eagerly loaded
        path: '**',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule)
    },
    
    

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    // this.router.navigateByUrl('/');
}
