<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-buttons slot="start">
				<ion-icon class="home-icon" name="ios-home" routerLink="/home" routerDirection="root"></ion-icon>
			</ion-buttons>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
	<ion-button class="highlight-btn" expand="block" routerLink={{parentPath}} routerDirection="back">
		{{parentName}}
	</ion-button>
	<ion-list>
		<ion-list-header class="ion-text-center"><p>Quizzes {{parentName}}</p></ion-list-header>
		<ion-item *ngFor="let quiz of quizzes" [routerLink]="['../../',category,'quiz', quiz.id]" button="true">
			<ion-label >
				<p class="quiz-title">
					{{quiz.title}}
				</p>
			</ion-label>
		</ion-item>
	</ion-list>
</ion-content>
